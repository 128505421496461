import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {navigate} from 'gatsby';
import React from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Button} from '../components';
import Layout from '../components/layout';
import RouterPaths from '../values/RouterPaths';
import '../styles/pages/404.scss';
import {use404} from '../hooks/queries';

// markup
const NotFoundPage = () => {
  const {
    title,
    headerTitle,
    headerSubtitle,
    headerOverline,
    headerBody,
    headerImage,
  } = use404();

  const image = getImage(headerImage.localFile);
  const {t} = useTranslation();

  const handleTakeMeHome = () => {
    navigate(RouterPaths.COMMERCIAL);
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <div className="four-oh-four pb-28">
        <GatsbyImage image={image} alt={headerImage.description} />
        <div className="four-oh-four-text">
          <p className="desktop:text-9xl tablet:text-9xl text-8xl font-semibold text-center">
            {headerTitle}
          </p>
          <p className="desktop:text-5xl text-4xl font-semibold text-center">
            {headerOverline} <br />
            {headerSubtitle}
          </p>

          <div className="text-center">
            <p className="text-xl font-semibold py-8">{headerBody}</p>
            <Button label={t('input:return')} handleClick={handleTakeMeHome} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
